import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../axios';

// Fetch Shopify collections
export const fetchCollections = createAsyncThunk(
  'shopify/fetchCollections',
  async () => {
    const response = await API.get('/shopify/collections');
    return response.data;
  }
);

// Start inventory update
export const startInventoryUpdate = createAsyncThunk(
  'shopify/startInventoryUpdate',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.post('/shopify/update-inventory');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Stop inventory update
export const stopInventoryUpdate = createAsyncThunk(
  'shopify/stopInventoryUpdate',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.post('/shopify/stop-inventory-update');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Fetch inventory progress
export const fetchInventoryProgress = createAsyncThunk(
  'shopify/fetchInventoryProgress',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get('/shopify/inventory-progress');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const shopifySlice = createSlice({
  name: 'shopify',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
    isUpdating: false,
    progress: null, // Holds progress data
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Collections
      .addCase(fetchCollections.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCollections.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchCollections.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Start Inventory Update
      .addCase(startInventoryUpdate.fulfilled, (state) => {
        state.isUpdating = true;
        state.error = null;
      })
      .addCase(startInventoryUpdate.rejected, (state, action) => {
        state.error = action.payload;
      })
      // Stop Inventory Update
      .addCase(stopInventoryUpdate.fulfilled, (state) => {
        state.isUpdating = false;
        state.progress = null; // Reset progress
      })
      .addCase(stopInventoryUpdate.rejected, (state, action) => {
        state.error = action.payload;
      })
      // Fetch Inventory Progress
      .addCase(fetchInventoryProgress.fulfilled, (state, action) => {
        state.progress = action.payload;
        state.isUpdating = action.payload.isUpdating; // Update state based on backend response
      })
      .addCase(fetchInventoryProgress.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default shopifySlice.reducer;
